import LocalizedStrings from 'react-localization';

const Strings = new LocalizedStrings({
    en: {
        Continue: 'Continue',
        Title: 'eWay-CRM Login',
        Error: 'Error',
        LoginToTitle: 'Login to eWay-CRM',
        ProtocolNotSupported: 'Only http(s) protocols are supported.',
        PleaseEnterValidWebServiceUrl: 'Please enter a valid Web Service URL.',
        EnterWebServiceUrl: 'Enter Web Service URL',
        EnterEmailAddress: 'Enter your email',
        Loading: 'Loading...',
        NeedAccount: 'Need an account?',
        NeedAccountLink: 'https://www.eway-crm.com/new-account/',
        LoginUsingWebService: 'Login using Web Service',
        LoginUsingEmail: 'Login using email',
        EmailAddressMayBeIncorrect: 'This email may be incorrect. Make sure you typed it correctly. Alternatively, you can login using your Web Service URL (mandatory for on-premise customers).',
        PasscodeIncorrect: 'Invalid verification code.',
        ResendCode: 'Resend Code',
        UnableToContactHostingDaemon: 'There has been error while contacting server. Please try again later.',
        EnterPasscodeMessage: 'Please enter verification code.',
        VerificationCodeSentMessage: 'An email with a verification code has just been sent. Enter the verification code here:',
        VerifyAccountTitle: 'Verify it\'s You'
    },
    cs : {
        Continue: 'Další',
        Title: 'eWay-CRM Přihlášení',
        Error: 'Chyba',
        LoginToTitle: 'Přihlášení do eWay-CRM',
        ProtocolNotSupported: 'Pouze http(s) protokoly jsou podporovány.',
        PleaseEnterValidWebServiceUrl: 'Prosím vložte platnou adresu webové služby.',
        EnterWebServiceUrl: 'Zadejte adresu webové služby',
        EnterEmailAddress: 'Zadejte e-mail',
        Loading: 'Načítání...',
        NeedAccount: 'Nemáte účet?',
        NeedAccountLink: 'https://www.eway-crm.com/cs/novy-ucet/',
        LoginUsingWebService: 'Zadat adresu webové služby',
        LoginUsingEmail: 'Přilásit se e-mailem',
        EmailAddressMayBeIncorrect: 'Zadaná e-mailová adresa pravděpodobně není správná. Zkontrolujte, zda je napsána správně. Případně místo e-mailu zadejte adresu webové služby (toto je nezbytené pro instalace na vlastním serveru).',
        PasscodeIncorrect: 'Neplatný ověřovací kód.',
        ResendCode: 'Znovu odeslat kód',
        UnableToContactHostingDaemon: 'Nastala chyba při komunikaci se serverem. Opakujte akci později.',
        EnterPasscodeMessage: 'Prosím zadejte ověřovací kód.',
        VerificationCodeSentMessage: 'E-mail s ověřovacím kódem byl právě odeslán. Ověřovací kód vložte zde:',
        VerifyAccountTitle: 'Ověření vaší totožnosti'
    },
    sk : {
        Continue: 'Ďalší',
        Title: 'eWay-CRM Prihlásenie',
        Error: 'Chyba',
        LoginToTitle: 'Prihlásenie do eWay-CRM',
        ProtocolNotSupported: 'Sú podporované iba protokoly http(s).',
        PleaseEnterValidWebServiceUrl: 'Prosím, vložte platnú adresu webovej služby.',
        EnterWebServiceUrl: 'Vložte adresu webovej služby',
        Loading: 'Načítanie...',
        NeedAccount: 'Nemáte účet?',
        NeedAccountLink: 'https://www.eway-crm.com/cs/novy-ucet/',
        PasscodeIncorrect: 'Neplatný overovací kód.',
        ResendCode: 'Znovu odoslať kód',
        EnterPasscodeMessage: 'Prosím, zadajte overovací kód.',
        VerificationCodeSentMessage: 'E-mail s overovacím kódom bol odoslaný. Overovací kód vložte sem:',
        VerifyAccountTitle: 'Overenie vašej totožnosti'
    },
    no : {
        Continue: 'Fortsett',
        Title: 'eWay-CRM Logg inn',
        Error: 'Feil',
        LoginToTitle: 'eWay-CRM Innlogging',
        ProtocolNotSupported: 'Bare http(s) protokoller støttes.',
        PleaseEnterValidWebServiceUrl: 'Vennligst angi en gyldig nettjeneste-URL.',
        EnterWebServiceUrl: 'Legg inn URL for nettjenesten',
        Loading: 'Laster inn...',
        NeedAccount: 'Behov for en konto?',
        NeedAccountLink: 'https://www.eway-crm.com/new-account/',
        PasscodeIncorrect: 'Ugyldig bekreftelseskode.',
        ResendCode: 'Legg inn koden på nytt',
        EnterPasscodeMessage: 'Vennligst skriv inn verifikasjonskoden.',
        VerificationCodeSentMessage: 'En E-post med bekreftelseskode ble sendt. Skriv inn bekreftelseskoden her:',
        VerifyAccountTitle: 'Bekreft at det er deg'
    }
});

export default Strings