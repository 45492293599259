import React, { useState } from 'react';
import { Helmet } from 'react-helmet'
import logo from './logo.svg';
import LoginForm from './components/LoginForm.js'
import Strings from './Strings.js';
import './styles.css';
import './Site.css';
import './App.css';

function App() {
  const queryString = require('query-string');
  const parameters = queryString.parse(window.location.search);
  if (parameters.ui_locales) {
    Strings.setLanguage(parameters.ui_locales);
  }

  var defaultErrorMessage = "";
  if (parameters.show_ws_error && parameters.url) {
    defaultErrorMessage = Strings.PleaseEnterValidWebServiceUrl;
  }

  var suppressWebServiceError = false;
  if (parameters.suppress_ws_error) {
    suppressWebServiceError = parameters.suppress_ws_error === "1";
  }

  const [errorMessage, setErrorMessage] = useState(defaultErrorMessage);
  const [title, setTitle] = useState(Strings.LoginToTitle);

  return (
    <>
      <Helmet>
        <title>{Strings.Title}</title>
      </Helmet>
      {(errorMessage) &&
      <div className="navbar alert-danger">
          <div className="navbar-header">
              <div className="navbar-text text-center">
                  <strong>{Strings.Error}: </strong>
                  {errorMessage}
              </div>
          </div>
      </div> }
      <div className="container page-login">
        <div className="row">
          <div className="panel panel-default">
            <div className="panel-heading">
              <div className="panel-brand">
                <img src={logo} alt="eWay-CRM" />
              </div>
              <h3 className="panel-title">{title}</h3>
            </div>
            <div className="panel-body">
              <LoginForm setTitle={setTitle} setErrorMessage={setErrorMessage} webServiceUrl={parameters.url} suppressWebServiceError={suppressWebServiceError} clientId={parameters.client_id} loginUsingWebService={parameters.show_ws_error || !!parameters.url} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
