import React from 'react';
import Strings from '../Strings.js';
import HostingApi, { PasscodeControl } from '@eway-crm/hostingdaemon-js-lib';
import ls from 'local-storage'
import sha256 from 'sha256';
import ResendCode from './../ResendCode.gif';
import ResendCodeStatic from './../ResendCodeStatic.gif';
import compareVersions from 'compare-versions';

const EMAIL_LS_KEY = 'email';
const LOGIN_HINT_HIDE_VERSION = '6.1.3.476';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      webServiceUrl: props.webServiceUrl,
      loading: false,
      loginUsingWebService: props.loginUsingWebService,
      email: ls(EMAIL_LS_KEY) ?? '',
      authorizationCode: null,
      passcode: '',
      resendCodeImage: ResendCodeStatic
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.hostingApi = new HostingApi(process.env.REACT_APP_HOSTING_DAEMON_URL, () => {
      this.props.setErrorMessage(Strings.UnableToContactHostingDaemon);
      this.setState({ loading: false });
    });
  }

  onSubmit(e) {
    e.preventDefault();

    this.process();
  }

  onPasscodeChange(passcode) {
    this.setState({ passcode: passcode }, () => {
      if (passcode.length !== 6)
        return;

      this.process();
    });
  }

  doMailVerification() {
    this.generateNewVerificationCode(() => this.setState({ loading: false }));
  }

  process() {
    // Remove possible previous error
    this.props.setErrorMessage(null);

    if (this.state.loginUsingWebService) {
      var url = this.fixWebServiceUrl(this.state.webServiceUrl);
      let username = null;

      // Test for input like 'https://stepan.havranek@eway-crm.com@hosting.eway-crm.com/ewaysystem'
      if (url.includes("@")) {
        let usernameStartIndex = 0;
        if (url.startsWith("https://")) {
          usernameStartIndex = 8;
        } else if (url.startsWith("http://")) {
          usernameStartIndex = 7;
        }
        let userNameEndIndex = url.lastIndexOf("@");

        username = url.substring(usernameStartIndex, userNameEndIndex);
        url = url.substring(0, usernameStartIndex) + url.substring(userNameEndIndex + 1);
      }

      this.testWebServiceUrl(url, username);
    } else if (!this.state.authorizationCode) {
      this.setState({ loading: true });

      const cachedUrl = ls(sha256(this.state.email));
      if (cachedUrl) {
        const hostingUrl = JSON.parse(cachedUrl);
        this.testWebServiceUrl(hostingUrl.Url, hostingUrl.UserName, hostingUrl.ClientVersion, true);
        return;
      }

      this.doMailVerification();
    } else {
      this.setState({ loading: true });

      this.hostingApi.getHostingsByEmail({
        email: this.state.email,
        authorizationCode: this.state.authorizationCode,
        passcode: this.state.passcode
      })
        .then((response) => {
          if (response) {
            if (response.HostingsUrls && response.HostingsUrls.length > 0) {
              const hostingUrl = response.HostingsUrls[0];
              ls(sha256(this.state.email), JSON.stringify(hostingUrl));
              ls(EMAIL_LS_KEY, this.state.email);
              this.goToUrl(hostingUrl.Url, hostingUrl.UserName, hostingUrl.ClientVersion);
            } else {
              this.props.setErrorMessage(Strings.PasscodeIncorrect);
              this.setState({ loading: false });
            }
          }
        });
    }
  }

  generateNewVerificationCode(endCallback) {
    this.hostingApi.generateUserEmailVerificationCode({
      email: this.state.email,
      lang: Strings.getLanguage()
    })
      .then((response) => {
        if (response) {
          if (!response.IsErrorResponse) {
            this.setState({ authorizationCode: response.AuthorizationCode });
            this.props.setTitle(Strings.VerifyAccountTitle);
          } else {
            this.props.setErrorMessage(Strings.EmailAddressMayBeIncorrect);
          }
        }
        endCallback();
      });
  }

  fixWebServiceUrl(url) {
    if (!url.endsWith("/")) {
      url += "/";
    }

    // Remove all spaces as they are never part of WS URL
    url = url.replace(" ", "");

    return url;
  }

  setHash(value) {
    if (window.location.hash === value) {
      window.location.hash = null;
    }

    window.location.hash = value;
  }

  testWebServiceUrl(url, userName = null, clientVersion = null, isUrlFromLocalStorage = false) {
    if (!url.startsWith("https://") && !url.startsWith("http://")) {
      this.props.setErrorMessage(Strings.ProtocolNotSupported);
      return;
    }

    url = this.fixWebServiceUrl(url);

    var request = new XMLHttpRequest();
    request.open('GET', url + "auth/.well-known/openid-configuration", true);
    request.onreadystatechange = function () {
      if (request.readyState === 4) {
        if (request.status === 200) {
          this.goToUrl(url, userName, clientVersion);
        } else {
          this.setState({ loading: false });
          this.setHash("url=" + encodeURIComponent(url) + "&error=1");

          if (window.CefSharp && !isUrlFromLocalStorage) {
            window.CefSharp.PostMessage("InvalidWSUrl;" + url);
          }

          // We do not want to report anything to the user when the webservice URL is from local storage
          if (!this.props.suppressWebServiceError && !isUrlFromLocalStorage) {
            this.props.setErrorMessage(Strings.PleaseEnterValidWebServiceUrl);
          }

          if (isUrlFromLocalStorage) {
            if (this.state.email) {
              ls.remove(sha256(this.state.email));
            }

            this.doMailVerification();
          }
        }
      }
    }.bind(this);
    this.setState({ loading: true });
    request.send();
  }

  goToUrl(url, userName = null, clientVersion = null) {
    url = this.fixWebServiceUrl(url);

    this.setHash("url=" + encodeURIComponent(url));

    // If there are no parameters redirect to the Welcome page
    // Ignore GA cross domain tracking
    if (window.location.search && !window.location.search.startsWith("?_gl=")) {
      url += "auth/connect/authorize" + window.location.search;

      if (userName && !url.includes('login_hint=')) {
        let loginForced = "true";
        if (clientVersion && compareVersions(clientVersion, LOGIN_HINT_HIDE_VERSION) >= 0) {
          loginForced = "hide";
        }

        url += '&login_forced=' + loginForced + '&login_hint=' + encodeURIComponent(userName);
      }
    } else {
      if (clientVersion && compareVersions(clientVersion, '8.0.0.110') >= 0) {
        url += "Auth.aspx?phase=login&client_id=web%20access&username=" + encodeURIComponent(userName) + "&redirect=%2F";
      } else {
        if (clientVersion && compareVersions(clientVersion, LOGIN_HINT_HIDE_VERSION) >= 0) {
          url += "Web/";
        } else {
          url += "WA/";
        }

        if (userName) {
          url += "Login.aspx?LoginHint=" + encodeURIComponent(userName) + "&LoginForced=true";
        }
      }
    }

    window.location.href = url;
  }

  resendCode() {
    this.setState({ resendCodeImage: ResendCode });

    setTimeout(function () {
      this.generateNewVerificationCode(() => this.setState({ resendCodeImage: ResendCodeStatic }));
    }.bind(this), 1000);
  }

  setEmail(email) {
    if (!this.isIE() && (email.startsWith('http://') || email.startsWith('https://'))) {
      this.setState({
        loginUsingWebService: true,
        webServiceUrl: email
      });

      return;
    }

    this.setState({ email: email });
  }

  setWebServiceUrl(webServiceUrl) {
    if (!this.isIE() && !webServiceUrl.startsWith('http://') && !webServiceUrl.startsWith('https://') && webServiceUrl.includes('@')) {
      this.setState({
        loginUsingWebService: false,
        email: webServiceUrl
      });

      return;
    }

    this.setState({ webServiceUrl: webServiceUrl });
  }

  isIE() {
    return !!/MSIE|Trident/.exec(navigator.userAgent);
  }

  render() {
    return (
      <form className="form" name="form" onSubmit={this.onSubmit}>
        <div className="form-group form-group-content">
          {this.state.loginUsingWebService && <input value={this.state.webServiceUrl} onChange={e => this.setWebServiceUrl(e.target.value)} required name="url" autoFocus id="url" type="url" className="form-control" placeholder={Strings.EnterWebServiceUrl} />}
          {!this.state.loginUsingWebService && !this.state.authorizationCode && <input value={this.state.email} onChange={e => this.setEmail(e.target.value)} required name="email" autoFocus id="email" type="email" className="form-control" placeholder={Strings.EnterEmailAddress} />}
          {this.props.clientId !== "mobileiphone" && this.props.clientId !== "mobileandroid" && !this.state.loginUsingWebService && !this.state.authorizationCode && <a className="btn btn-link pl-0" href={Strings.NeedAccountLink}>{Strings.NeedAccount}</a>}
          {!this.state.loginUsingWebService && this.state.authorizationCode &&
            <>
              <div className="success-text">
                <p>{Strings.VerificationCodeSentMessage}</p>
              </div>
              <div className="text-center">
                <PasscodeControl onPasscodeChange={this.onPasscodeChange.bind(this)} customValidityMessage={Strings.EnterPasscodeMessage} />
              </div>
            </>
          }
        </div>
        <div className="form-group form-group-buttons-and-links">
          <div className="form-group-bottom-flex">
            <ul className="list-unstyled form-bottom-links">
              {!this.state.loginUsingWebService && !this.state.authorizationCode && <li><button type="button" className="btn btn-link btn-sm p-0" onClick={() => this.setState({ loginUsingWebService: true })}>{Strings.LoginUsingWebService}</button></li>}
              {this.state.loginUsingWebService && <li><button type="button" className="btn btn-link btn-sm p-0" onClick={() => this.setState({ loginUsingWebService: false })}>{Strings.LoginUsingEmail}</button></li>}
              {!this.state.loginUsingWebService && this.state.authorizationCode && <><li><img id="resend-code" alt="" className="resend-code-img" src={this.state.resendCodeImage} /><button type="button" onClick={this.resendCode.bind(this)} className="btn btn-link btn-sm p-0" id="resend-code-button">{Strings.ResendCode}</button></li></>}
            </ul>
            <button className="btn btn-primary" disabled={this.state.loading}>
              {this.state.loading && <div className="spinner-border spinner-border-sm" role="status">
                <span className="sr-only">{Strings.Loading}</span>
              </div>}
              {!this.state.loading && Strings.Continue}
            </button>
          </div>
        </div>
      </form>
    );
  }
}

export default LoginForm;